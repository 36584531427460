@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main-color: #000b24;
    --primary-color: #939EE1;
    --dark-color: #0C1765;
    --light-color: #BCC1DD;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
  }
  
  .navActive{
    color: var(--main-color) !important; 
    }
  