/* Navbar.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

nav {
  background-color:#000b24;
  color: white;
  padding: 0rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

nav p{
  font-size: 15.2px;
}

nav img {
  margin: 0;
  padding: 0;
  width: 250px;
  margin-left: -10px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 6rem;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
}
nav a:hover{
  color:#939EE1;
  transition: all 0.5s ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 123px;
    left: 0;
    width: 100%;
    background-color: #000b24;
    display: none;
  }

  nav ul.show {
    display: flex;
  }

  nav h1{
    margin-bottom: -100px;
    display: none;
  }

  nav li {
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
  }

  nav li:last-child {
    margin-bottom: 1rem;
  }
  
  nav .menu-toggle {
    display: block;
    cursor: pointer;
  }

  nav .menu-toggle span {
    background-color: white;
    display: block;
    height: 3px;
    width: 20px;
    margin: 3px;
    transition: 0.3s;
  }

  nav .menu-toggle.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  nav .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  nav .menu-toggle.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
