.client-logo-wrap {
  position: relative;
  overflow: hidden;
  height: 200px;
}

.client-logo {
  position: absolute;
  top: 60px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  margin: auto;
  padding: auto;
}

.client-logo-left {
  animation: logomoveleft 7s linear infinite;
}
.client-logo li img{
  mix-blend-mode: color-dodge;
}
.client-logo li {
  mix-blend-mode: multiply;
  border-radius: 8px;
  padding: 20px !important;
  height: 100px;
  width: 250px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0px 100px;
}


/*
.second-row{ 
  top: 150px;
}*/

@keyframes logomoveleft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-30%, 0);
  }
}
